import { Button } from "@mui/material";
import SelectItem from "./SelectItem";
import { Product, Comune, SolarProduct } from "../types/types";
import dataComuni from "../data/comuni-zona-climatiche.json"
import dataProducts from "../data/products.json"
import dataSolarProducts from "../data/solar_products.json"
import dataSolarServices from "../data/solar_services.json"

interface Props {
    disabled: boolean;
    selectedProduct: string | null;
    selectedComune: string | null;
    selectedService: string | null;
    selectedPanels: string | null;
    setProduct: (data: string) => void;
    setComune: (data: string | null) => void;
    setService: (data: string | null) => void;
    setPanels: (data: string | null) => void;
    setResults: () => void;
}

export default function Search({ setProduct, setComune, setResults, setService, setPanels, selectedProduct, selectedComune, selectedPanels, selectedService, disabled }: Props) {
    const listaComuni: Comune[] = dataComuni;
    const listaProduct: Product[] = dataProducts;
    const listaSolar: SolarProduct[] = dataSolarProducts;

    return (
        <div className='flex justify-center border md:border-solid border-[#D8D8D8] rounded-2xl md:mx-5 md:py-10'>
            <div className="flex flex-col items-center justify-center w-full max-w-[784px] px-5 gap-10">
                <div className="w-full md:text-center">
                    <h4 className='uppercase font-black'>CALCOLA L'INCENTIVO PER IL CONTO TERMICO</h4>
                </div>
                <div className='flex flex-col gap-6 md:gap-8 mb-10 w-full'>
                    <SelectItem
                        data={listaProduct.filter(i => i.hide === false).map(i => i.modello).concat(listaSolar.filter(i => i.hide === false).map(i => i.modello))}
                        onChange={value => {
                            setProduct(value);
                            setComune(null);
                            setPanels(null);
                            setService(null);
                        }}
                        value={selectedProduct}
                        label="Modello"
                        placeholder="Seleziona modello"
                    />
                    {selectedProduct && selectedProduct !== "AGUAmax 300 -300/1" ? selectedProduct === "Harmony" || selectedProduct === "Solar Plus V" ?
                        <>
                            <SelectItem
                                data={dataSolarServices.map(i => i.nome)}
                                value={selectedService}
                                onChange={value => setService(value)}
                                label="Servizio"
                                placeholder="Seleziona servizio"
                            />
                            <SelectItem
                                value={selectedPanels?.toString()}
                                data={Array.from({ length: 6 }, (_, i) => (i + 1).toString())}
                                onChange={value => setPanels(value)}
                                label="Pannelli"
                                placeholder="Seleziona n. pannelli"
                            />
                        </>
                        :
                        <SelectItem
                            value={selectedComune}
                            data={listaComuni.map(i => { return (i.nome) })}
                            onChange={value => setComune(value)}
                            label="Comune"
                            placeholder="Seleziona comune"
                        />
                        : ""}
                </div>
                <Button
                    variant='contained'
                    onClick={e => setResults()}
                    disabled={disabled}
                    fullWidth
                    className='bg-blue disabled:bg-gray hover:bg-yellow hover:text-blue text-white rounded-none text-xl p-4 font-black w-full md:max-w-[320px] normal-case'
                >
                    Calcola
                </Button>
            </div>
        </div>
    );
}