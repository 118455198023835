import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import "./index.css"
import { createTheme, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from "@mui/material";
import { Helmet } from 'react-helmet';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as HTMLElement)
const canonicalUrl = "https://www.cosmogas.com/perche-cosmogas/simulatore-conto-termico/";

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>
);