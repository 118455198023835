import { useState } from 'react';
import { Container } from '@mui/material';
import Search from './components/Search';
import Results from './components/Results';

export default function App() {
  const [selectedComune, setSelectedComune] = useState<string | null>(null)
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
  const [selectedService, setSelectedService] = useState<string | null>(null)
  const [selectedPanels, setSelectedPanels] = useState<string | null>(null)
  const [showResults, setShowResults] = useState(false);

  console.log(selectedComune)

  function setComune(data: string | null) {
    setSelectedComune(data);
  }

  function setProduct(data: string | null) {
    setSelectedProduct(data);
  }

  function setShow() {
    setShowResults(value => !value)
  }

  function setService(data: string | null) {
    setSelectedService(data);
  }

  function setPanels(data: string | null) {
    setSelectedPanels(data);
  }

  return (
    <Container
      sx={{ padding: "0 !important" }}
    >
      <div className="flex flex-col mx-auto gap-8 md:gap-10 my-8 font-lato text-blue max-w-[1300px]">
        <div className='flex flex-col gap-2 md:gap-5 mx-5'>
          <h3 className='font-bold'>Che cos'è il Conto Termico</h3>
          <p className='text-[18px]'>
            Il CONTO TERMICO è un incentivo erogato dal GSE (Gestore Servizi Energetici), alternativo e NON CUMULABILE con la detrazione fiscale in 10 anni del 65%- 50%. Questo incentivo è particolarmente vantaggioso in quanto l’importo spettante verrà accreditato direttamente sul conto corrente entro 30 giorni dal bimestre in cui è stata sottoscritta la scheda-contratto.
          </p>
        </div>
        <div className='md:hidden w-full h-[1px] bg-gray/80'></div>
        {showResults ?
          <Results
            setComune={setComune}
            setProduct={setProduct}
            setShow={setShow}
            comune={selectedComune}
            modello={selectedProduct!}
            panels={selectedPanels}
            service={selectedService}
          />
          :
          <Search
            selectedProduct={selectedProduct}
            selectedComune={selectedComune}
            selectedPanels={selectedPanels}
            selectedService={selectedService}
            setComune={setComune}
            setProduct={setProduct}
            setResults={setShow}
            setService={setService}
            setPanels={setPanels}
            disabled={selectedProduct && (selectedComune || selectedPanels && selectedService) || selectedProduct === "AGUAmax 300 -300/1" ? false : true}
          />
        }
        <div className='md:hidden w-full h-[1px] bg-gray/80'></div>
      </div>
    </Container>
  );
}