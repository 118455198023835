import { Button } from "@mui/material"
import { Comune, Product, SolarProduct, SolarService } from "../types/types"
import "./Results.css"
import dataComuni from "../data/comuni-zona-climatiche.json"
import dataProducts from "../data/products.json"
import dataSolar from "../data/solar_products.json"
import dataSolarServices from "../data/solar_services.json"

interface Props {
    modello: string;
    comune: string | null;
    service: string | null;
    panels: string | null;
    setShow: () => void;
    setComune: (data: null) => void;
    setProduct: (data: null) => void;
}

export default function Results({ comune, modello, service, panels, setShow, setComune, setProduct }: Props) {
    const selectedComune = dataComuni.find(i => i.nome === comune);
    const selectedProduct: Product | undefined = dataProducts.find(i => i.modello === modello);
    const selectedSolarProduct: SolarProduct | undefined = dataSolar.find(i => i.modello === modello);
    const selectedService: SolarService | undefined = dataSolarServices.find(i => i.nome === service);

    return (
        <div className="flex justify-center border md:border-solid border-[#D8D8D8] rounded-2xl md:mx-5">
            <div className="flex flex-col items-center md:py-10 px-5 max-w-[784px]">
                <div className="w-full md:text-center">
                    <h4 className='uppercase font-black'>Il risultato del conto termico</h4>
                </div>
                <div className='flex flex-col gap-4 pt-8 items-center'>
                    <div className='result-row'>
                        <label>Il tuo modello</label>
                        <div className='result'>{selectedProduct?.modello || selectedSolarProduct?.modello}</div>
                    </div>
                    {selectedProduct &&
                        <>
                            <div className='result-row'>
                                <label>Pn - Potenza termica nominale della pompa di calore installata</label>
                                <div className='result'>{selectedProduct.pn.toLocaleString("it-IT")}</div>
                            </div>
                            {selectedComune &&
                                <>
                                    <div className='result-row'>
                                        <label>Zona Climatica</label>
                                        <div className='result'>{selectedComune.zona}</div>
                                    </div>
                                </>
                            }
                            <div className='result-row'>
                                <label>COP - Coefficiente di prestazione della pompa di calore installata </label>
                                <div className='result'>{selectedProduct.cop?.toLocaleString("it-IT")}</div>
                            </div>
                            {selectedProduct.incentivi && selectedComune &&
                                <div className='result-row bg-gray-light'>
                                    <label>Incentivo totale [€]*</label>
                                    <div className='result'>€ {selectedProduct.incentivi[selectedComune.zona].toLocaleString("it-IT")}</div>
                                </div>
                            }
                            {selectedProduct.incentivoSingolo &&
                                <div className='result-row bg-gray-light'>
                                    <label>Incentivo totale [€]*</label>
                                    <div className='result'>{selectedProduct.incentivoSingolo}</div>
                                </div>
                            }
                        </>
                    }
                    {
                        selectedSolarProduct && selectedService && panels &&
                        <>
                            <div className='result-row'>
                                <label>Servizio</label>
                                <div className='result'>{selectedService.nome}</div>
                            </div>
                            <div className='result-row'>
                                <label>Numero pannelli</label>
                                <div className='result'>{panels}</div>
                            </div>
                            <div className='result-row'>
                                <label>Qu - Energia termica prodotta per singolo modulo</label>
                                <div className='result'>{selectedSolarProduct.qu.toLocaleString("it-IT")}</div>
                            </div>
                            <div className='result-row'>
                                <label>Qcol - Energia termica prodotta in un anno dal singolo modulo</label>
                                <div className='result'>{selectedSolarProduct.qcol.toLocaleString("it-IT")}</div>
                            </div>
                            <div className='result-row'>
                                <label>Ag - Area lorda del pannello solare</label>
                                <div className='result'>{selectedSolarProduct.ag.toLocaleString("it-IT")}</div>
                            </div>
                            <div className='result-row'>
                                <label>Sl - Superfice solare lorda</label>
                                <div className='result'>{selectedSolarProduct.servizio[selectedService.id].pannelli[panels].sl.toLocaleString("it-IT")}</div>
                            </div>
                            <div className='result-row bg-gray-light'>
                                <label>Incentivo totale [€]*</label>
                                <div className='result'>€ {selectedSolarProduct.servizio[selectedService.id].pannelli[panels].incentivo.toLocaleString("it-IT")}</div>
                            </div>
                        </>
                    }
                    <div className='flex flex-col gap-8'>
                        <p className='w-full text-sm'>*Incentivo fino a 5.000€ erogato in unica rata.</p>
                        <p className='font-black leading-6'>
                            COSMOGAS fornisce solo una stima dell'incentivo ottenibile con il Conto Termico. È responsabilità del richiedente verificare il soddisfacimento di tutte le condizioni necessarie per aderire al Conto Termico. I valori indicati possono subire variazioni senza preavviso, pertanto questo documento non può essere considerato un contratto vincolante nei confronti di terzi.
                        </p>
                        <Button
                            variant='contained'
                            onClick={e => {
                                setShow()
                                setComune(null)
                                setProduct(null)
                            }}
                            className='mx-auto bg-white hover:bg-yellow text-blue border-blue rounded-none border border-solid text-xl p-4 font-black w-full md:max-w-[320px] normal-case'
                            fullWidth
                        >
                            Modifica parametri iniziali
                        </Button>
                    </div>
                </div>
            </div>
        </div>)
}