import { Autocomplete, TextField } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Comune, Product } from "../types/types";

interface Props {
    label: string;
    value: string | null | undefined;
    placeholder: string;
    data: string[];
    onChange: (data: string) => void;
};

export default function SelectItem({ label, placeholder, data, value, onChange }: Props) {
    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col gap-4 w-full md:max-w-[376px]">
                <div className="text-xl font-black text-blue">{label}</div>
                <Autocomplete
                    id="combo-box-items"
                    options={data}
                    disablePortal
                    value={value}
                    popupIcon={<KeyboardArrowDownIcon htmlColor="#072442" />}
                    sx={{
                        height: 56,
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#062342",
                        },
                    }}
                    fullWidth
                    onChange={(event, value) => onChange(value!)}
                    renderInput={(params) => <TextField {...params} placeholder={placeholder} fullWidth />}
                />
            </div>
        </div>
    )
}